<template>
  <v-card class="ma-3">
    <list-header title='GreatScots Campus Directory' :groupable="true" :filterable="true" :field="field" :query="query" @print="printList"></list-header>
    <v-card-text v-if="filterActive">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select v-model="field" :items="fieldOptions" label="Search Field"></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-if="field==='name'" v-model="text" label="Name Search"></v-text-field>
          <v-autocomplete v-if="field !== 'name'" v-model="text" :label="searchLabel" :items="searchAutoCompleteItems"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-select v-model="types" :items="typeOptions" label="Person Types" multiple></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <main-view ref="mainView" :people="people" :group="group" @reload="loadSearch"></main-view>
    <page-footer :total="total" @reload="loadSearch"></page-footer>
    <v-card-text>
      <p>Notice something incorrect? Email <a href="mailto:techsupport@covenant.edu?subject=Incorrect+Info+in+GreatScots+Directory" target="_blank">techsupport@covenant.edu</a> to report the issue.</p>
    </v-card-text>
  </v-card>
</template>
<script>
import { ref, reactive, computed, watch, onMounted } from '@vue/composition-api'

export default {
  components: {
    MainView: () => import('@/components/greatscots/View'),
    ListHeader: () => import('@/components/greatscots/Header'),
    PageFooter: () => import('@/components/greatscots/Footer')
  },
  setup (props, { root }) {
    const people = ref([])
    const total = ref(0)
    const mainView = ref(null)

    const group = computed(() => { return root.$store.state.greatscots.listGroup })
    const groupIncludesBlanks = computed(() => { return root.$store.state.greatscots.listGroupIncludeBlanks })

    const fieldOptions = reactive([
      { text: 'Name', value: 'name' },
      { text: 'Department', value: 'location.department' },
      { text: 'Building', value: 'location.building' },
      { text: 'Hall', value: 'location.hall' }
    ])
    const field = computed({
      get: () => {
        return root.$store.state.greatscots.filter.field || 'name'
      },
      set: (val) => {
        root.$store.commit('greatscots/setFilter', { field: val })
      }
    })

    const text = computed({
      get: () => {
        return root.$store.state.greatscots.filter.text
      },
      set: (val) => {
        root.$store.commit('greatscots/setFilter', { text: val })
      }
    })
    watch(field, (field, prevField) => {
      if (prevField !== field) {
        text.value = ''
        if (field === 'name') root.$store.commit('greatscots/setFilter', { options: [] })
        else root.$store.dispatch('greatscots/loadFieldOptions', { field })
      }
    })

    const typeOptions = reactive([
      { text: 'Faculty', value: 'Faculty' },
      { text: 'Staff', value: 'Staff' },
      { text: 'Student', value: 'Student' }
    ])
    const types = computed({
      get: () => {
        return root.$store.state.greatscots.filter.types
      },
      set: (val) => {
        root.$store.commit('greatscots/setFilter', { types: val })
      }
    })

    const searchAutoCompleteItems = computed(() => {
      return root.$store.state.greatscots.filter.options
    })
    const searchLabel = computed(() => {
      for (let i = 0; i < fieldOptions.length; i++) {
        if (fieldOptions[i].value === field.value) {
          return 'Select ' + fieldOptions[i].text
        }
      }
      return 'Search ' + field.value
    })

    const query = computed(() => {
      const { sortBy, sortDesc, page, itemsPerPage } = root.$store.state.greatscots.resultOptions
      let query = {
        active: true,
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {}
      }
      let { listGroup } = root.$store.state.greatscots
      if (root.$store.state.greatscots.listGroup !== undefined) {
        let included = false
        for (let i = 0; i < sortBy.length; i++) {
          if (sortBy[i] === listGroup) {
            query.$sort[listGroup] = sortDesc[i]
            included = true
            break
          }
        }
        if (!included) query.$sort[listGroup] = 1
        if (!root.$store.state.greatscots.listGroupIncludeBlanks) {
          query[listGroup] = { $ne: '' }
        }
      }
      for (let i = 0; i < sortBy.length; i++) {
        if (sortBy[i] === listGroup) continue
        query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
      }
      switch (field.value) {
        case 'name':
          if (text.value.trim() !== '') {
            if (text.value.search(',') > 0) {
              let temp = text.value.split(',')
              query['name.last'] = { $regex: temp[0].trim(), $options: 'i' }
              query['name.first'] = { $regex: temp[1].trim(), $options: 'i' }
            } else if (text.value.search(' ') > 0) {
              let temp = text.value.split(' ')
              query['name.first'] = { $regex: temp[0].trim(), $options: 'i' }
              query['name.last'] = { $regex: temp[1].trim(), $options: 'i' }
            } else {
              query.$or = [
                { 'name.last': { $regex: text.value.trim(), $options: 'i' } },
                { 'name.first': { $regex: text.value.trim(), $options: 'i' } }
              ]
            }
          }
          break
        default:
          // All search options besides name are currently from a select menu so we don't need to do a fuzzy search like with name
          query[field.value] = text.value
          break
      }
      if (Array.isArray(types.value) && types.value.length > 0) {
        query['person'] = { $in: types.value }
      }
      return query
    })

    const loadSearch = () => {
      root.$feathers.service('directory/people').find({ query: query.value }).then((res) => {
        if (res.total > 0 && res.data.length === 0) {
          let options = root.$store.state.greatscots.resultOptions
          options.page = Math.ceil(res.total / options.itemsPerPage)
          root.$store.commit('setResultOptions', options)
          loadSearch()
        } else {
          total.value = res.total
          people.value = res.data
        }
      })
    }

    watch([ field, text, types, group, groupIncludesBlanks ], () => {
      loadSearch()
    })

    onMounted(() => { loadSearch() })

    function printList () {
      mainView.value.print()
    }

    const isSmall = computed(() => root.$vuetify.breakpoint.name === 'xs' || root.$vuetify.breakpoint.name === 'sm')
    const filterActive = computed(() => {
      return !isSmall.value || root.$store.state.greatscots.filter.active
    })

    return {
      people,
      total,
      mainView,
      group,
      groupIncludesBlanks,
      field,
      text,
      types,
      fieldOptions,
      typeOptions,
      searchAutoCompleteItems,
      searchLabel,
      query,
      loadSearch,
      printList,
      filterActive
    }
  }
}
</script>
